import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = (props) => {
  let title = "404: Page Not Found - L&D Solutions Inc."
  let location = props.location.pathname
  return (
    <Layout>
      <Seo title={title} location={location} />
      <div className="text-black pt-72 text-center pb-96 bg-newlndgray px-10">
        <h1 className="text-3xl font-bold pb-5">404: Page Not Found</h1>
        <p className="text-3xl max-w-5xl mx-auto font-medium leading-relaxed">
          Check the web address and try again or return to the L&D Solutions Inc. Home Page
        </p>
        <Link to="/" title="Go to L&D Solutions Home Page" alt="Go to L&D Solutions Home Page">
          <button className="mt-10 relative h-10 px-6 text-white transition-colors bg-lndred rounded-lg border border-lndred focus:shadow-outline hover:bg-transparent hover:text-black transition duration-150 ease-in-out">
            <span>Home Page</span>
          </button>
        </Link>
      </div>
    </Layout>
  )
}
export default NotFoundPage
